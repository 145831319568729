import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import useAuth from '../hooks/useAuth';

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { login, register, user, error, setError } = useAuth();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setFormData({});
    setError(null);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await login(formData);
      } else {
        await register(formData);
      }
      navigate('/');
    } catch (error) {
      console.error('Authentication error:', error);
      // Error is already set in the AuthContext
    }
  };

  return (
    <div className="app-container">
      <div className={`form-container ${!isLogin ? 'hidden' : ''}`}>
        <h1>Welcome Back</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <Input type="email" name="email" placeholder="Email" onChange={handleChange} required />
          </div>
          <div className="input-group">
            <Input type="password" name="password" placeholder="Password" onChange={handleChange} required />
          </div>
          {error && <div className="error-message">{error}</div>}
          <Button type="submit">Log In</Button>
        </form>
        <div className="switch-form">
          Don't have an account? <button onClick={toggleForm}>Sign Up</button>
        </div>
      </div>

      <div className={`form-container ${isLogin ? 'hidden' : ''}`}>
        <h1>Create Account</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <Input type="text" name="first_name" placeholder="First Name" onChange={handleChange} required />
          </div>
          <div className="input-group">
            <Input type="text" name="last_name" placeholder="Last Name" onChange={handleChange} required />
          </div>
          <div className="input-group">
            <Input type="email" name="email" placeholder="Email" onChange={handleChange} required />
          </div>
          <div className="input-group">
            <Input type="password" name="password" placeholder="Password" onChange={handleChange} required />
          </div>
          <div className="input-group">
            <Input type="text" name="company_name" placeholder="Company Name" onChange={handleChange} />
          </div>
          <div className="input-group">
            <Input type="tel" name="company_phone" placeholder="Company Phone" onChange={handleChange} />
          </div>
          <div className="input-group">
            <Input type="email" name="company_email" placeholder="Company Email" onChange={handleChange} />
          </div>
          {error && <div className="error-message">{error}</div>}
          <Button type="submit">Sign Up</Button>
        </form>
        <div className="switch-form">
          Already have an account? <button onClick={toggleForm}>Log In</button>
        </div>
      </div>
    </div>
  );
};

export default Login;