import React, { useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/common/Button';
import Modal from '../components/common/Modal';
import Input from '../components/common/Input';

const MyCustomers = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        // Simulating API call to fetch customers
        setCustomers([
            { id: 1, name: 'Alice Johnson', company: 'Green Solutions Inc.', email: 'alice@greensolutions.com' },
            { id: 2, name: 'Bob Smith', company: 'TechInnovate LLC', email: 'bob@techinnovate.com' },
            { id: 3, name: 'Carol Williams', company: 'EcoBuilders Co.', email: 'carol@ecobuilders.com' }
        ]);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const handleAddCustomer = () => {
        setCurrentCustomer(null);
        setIsModalOpen(true);
    };

    const handleEditCustomer = (customer) => {
        setCurrentCustomer(customer);
        setIsModalOpen(true);
    };

    const handleDeleteCustomer = (customer) => {
        setCurrentCustomer(customer);
        setIsDeleteModalOpen(true);
    };

    const handleSaveCustomer = (customer) => {
        if (customer.id) {
            setCustomers(customers.map(c => c.id === customer.id ? customer : c));
        } else {
            setCustomers([...customers, { ...customer, id: Date.now() }]);
        }
        setIsModalOpen(false);
    };

    const handleConfirmDelete = () => {
        setCustomers(customers.filter(c => c.id !== currentCustomer.id));
        setIsDeleteModalOpen(false);
    };

    return (
        <Layout isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar}>
            <div className="my-customers">
                <div className="header">
                    <h1> </h1>
                    <Button className="new-offer-btn" onClick={handleAddCustomer}>Add New Customer</Button>
                </div>
                <div className="customer-list">
                    {customers.map(customer => (
                        <div key={customer.id} className="customer-item">
                            <div className="customer-info">
                                <h3>{customer.name}</h3>
                                <p>{customer.company}</p>
                                <p>{customer.email}</p>
                            </div>
                            <div className="customer-actions">
                                <Button onClick={() => handleEditCustomer(customer)}>Edit</Button>
                                <Button onClick={() => handleDeleteCustomer(customer)}>Delete</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={currentCustomer ? "Edit Customer" : "Add New Customer"}>
                <CustomerForm customer={currentCustomer} onSave={handleSaveCustomer} onCancel={() => setIsModalOpen(false)} />
            </Modal>
            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} title="Confirm Delete">
                <p>Are you sure you want to delete {currentCustomer?.name}?</p>
                <div className="modal-actions">
                    <Button onClick={handleConfirmDelete}>Yes, Delete</Button>
                    <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                </div>
            </Modal>
        </Layout>
    );
};

const CustomerForm = ({ customer, onSave, onCancel }) => {
    const [formData, setFormData] = useState(customer || { name: '', company: '', email: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Name" 
                required 
            />
            <Input 
                type="text" 
                name="company" 
                value={formData.company} 
                onChange={handleChange} 
                placeholder="Company" 
                required 
            />
            <Input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                placeholder="Email" 
                required 
            />
            <div className="form-actions">
                <Button type="submit">Save</Button>
                <Button type="button" onClick={onCancel}>Cancel</Button>
            </div>
        </form>
    );
};

export default MyCustomers;