import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import Button from '../components/common/Button';
import { generateOfferPDF, generateOfferExcel } from '../services/api';

const OfferSummary = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { offerId, customer } = location.state || {};

    useEffect(() => {
        if (!offerId) {
            console.error('No offerId available.');
            setError('No offer ID available. Please try again.');
        }
    }, [offerId]);

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const openOffer = async (type) => {
        setIsGenerating(true);
        setError(null);
        try {
            let response;
            if (type === 'pdf') {
                response = await generateOfferPDF(offerId);
            } else if (type === 'excel') {
                response = await generateOfferExcel(offerId);
            }
            console.log('API Response:', response);

            if (response && response.data && response.data.status) {
                const fileUrl = type === 'pdf' ? response.data.pdfUrl : response.data.excelUrl;
                if (fileUrl) {
                    const fullUrl = `${fileUrl}`;
                    console.log(`Opening ${type} in new tab:`, fullUrl);

                    // Yeni sekmede aç
                    window.open(fullUrl, '_blank');

                    // localStorage'daki ilgili alanları sil ve dashboard'a yönlendir
                    localStorage.removeItem('currentRequestId');
                    localStorage.removeItem('offerId');
                    navigate('/dashboard');
                } else {
                    throw new Error(`No ${type} URL provided in the response`);
                }
            } else {
                throw new Error(`Failed to generate ${type}`);
            }
        } catch (error) {
            console.error(`Error generating ${type}:`, error);
            setError(`Failed to generate ${type}. Please try again.`);
        } finally {
            setIsGenerating(false);
        }
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="dashboard">
            <Navbar isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar} />
            <main className="main-content">
                <div className="offer-summary">
                    <div className="congrats">Congratulations!</div>
                    <div className="offer-details">
                        <p><strong>Offer Number:</strong> {offerId}</p>
                        <p><strong>Customer Name:</strong> {customer?.first_name} {customer?.last_name}</p>
                        <p><strong>Customer Company:</strong> {customer?.company_name || 'Unknown Company'}</p>
                        <p><strong>Offer Date:</strong> {new Date().toLocaleDateString()}</p>
                    </div>
                    <div className="open-options">
                        <Button 
                            className="open-btn" 
                            onClick={() => openOffer('pdf')}
                            disabled={isGenerating}
                        >
                            <i className="fas fa-file-pdf"></i>
                            <span>{isGenerating ? 'Generating...' : 'Open PDF'}</span>
                        </Button>
                        <Button 
                            className="open-btn" 
                            onClick={() => openOffer('excel')}
                            disabled={isGenerating}
                        >
                            <i className="fas fa-file-excel"></i>
                            <span>{isGenerating ? 'Generating...' : 'Open Excel'}</span>
                        </Button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default OfferSummary;