import React, { useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/common/Button';
import Edit from '../components/common/Edit';


const Products = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [products, setProducts] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);

    useEffect(() => {
        // Simulating API call to fetch products
        setProducts([
            { id: 1, name: 'LED Bulb', brand: 'EcoLight', price: 9.99, quantity: 100, code: 'LED001', wattage: 9, category: 'Lighting', lightColor: 'Warm White', currency: 'USD' },
            { id: 2, name: 'Smart Switch', brand: 'TechHome', price: 24.99, quantity: 50, code: 'SS002', wattage: 0, category: 'Smart Home', lightColor: 'N/A', currency: 'USD' },
            { id: 3, name: 'Solar Panel', brand: 'SunPower', price: 199.99, quantity: 25, code: 'SP003', wattage: 100, category: 'Renewable Energy', lightColor: 'N/A', currency: 'USD' },
        ]);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const handleEdit = (productId) => {
        setEditingProductId(productId);
    };

    const handleSave = (productId) => {
        console.log(`Saving changes for product ${productId}`);
        setEditingProductId(null);
        // Here you would typically send the updated product data to your backend
    };

    const handleAddProduct = () => {
        console.log('Add new product');
        // Here you would typically open a modal or navigate to a new product form
    };

    return (
        <Layout isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar}>
            <div className="products">
                <div className="header">
                    <h1>Products</h1>
                    <Button onClick={handleAddProduct}>Add New Product</Button>
                </div>
                <div className="product-list">
                    {products.map(product => (
                        <div key={product.id} className="product-item">
                            <div className="product-info">
                                <h3>{product.name}</h3>
                                <p>Brand: {product.brand} | Price: ${product.price} | Quantity: {product.quantity}</p>
                                <p>Code: {product.code} | Wattage: {product.wattage}W | Category: {product.category}</p>
                            </div>
                            <div className="product-actions">
                                <Button onClick={() => handleEdit(product.id)}>Edit</Button>
                            </div>
                            {editingProductId === product.id && (
                                <Edit product={product} onSave={() => handleSave(product.id)} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default Products;