import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import { FaEdit, FaTrash, FaSave, FaTimes, FaPaperclip } from 'react-icons/fa';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import useAuth from '../hooks/useAuth';
import { createRequest, addMessage, getRequestMessages, analyzeAndAddProducts, updateMessage, deleteMessage } from '../services/api';

const CreateRequest = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [requestId, setRequestId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const messageEndRef = useRef(null);
    const { user } = useAuth();
    const [editingMessageId, setEditingMessageId] = useState(null);
    const [editedMessageContent, setEditedMessageContent] = useState('');

    useEffect(() => {
        const savedRequestId = localStorage.getItem('currentRequestId');
        if (savedRequestId) {
            setRequestId(savedRequestId);
            fetchMessages(savedRequestId);
        } else {
            createNewRequest();
        }
    }, []);

    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const createNewRequest = async () => {
        try {
            const response = await createRequest({ status: 'Draft' });
            setRequestId(response.data.id);
            localStorage.setItem('currentRequestId', response.data.id);
        } catch (error) {
            setError('Failed to create new request. Please try again.');
        }
    };

    const fetchMessages = async (requestId) => {
        try {
            const response = await getRequestMessages(requestId);
            setMessages(response.data);
        } catch (error) {
            setError('Failed to fetch messages. Please refresh the page.');
        }
    };

    const toggleSidebar = () => setIsSidebarActive(!isSidebarActive);

    const handleMessageChange = (e) => setMessage(e.target.value);

    const handleAddMessage = async () => {
        if (message.trim() !== '' && requestId) {
            try {
                const response = await addMessage(requestId, message);
                setMessages(prevMessages => [...prevMessages, { content: message, sender: 'user', id: response.data.id }]);
                setMessage('');
            } catch (error) {
                setError('Failed to add message. Please try again.');
            }
        } else {
            setError('Message content is required.');
        }
    };

    const handleEditMessage = (messageId, content) => {
        setEditingMessageId(messageId);
        setEditedMessageContent(content);
    };

    const handleCancelEdit = () => {
        setEditingMessageId(null);
        setEditedMessageContent('');
    };

    const handleSaveEdit = async (messageId) => {
        if (editedMessageContent.trim() === '') {
            setError('Message content is required.');
            return;
        }

        try {
            await updateMessage(requestId, messageId, editedMessageContent);
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === messageId ? { ...msg, content: editedMessageContent } : msg
                )
            );
            handleCancelEdit();
        } catch (error) {
            setError('Failed to update message. Please try again.');
        }
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            await deleteMessage(requestId, messageId);
            setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
        } catch (error) {
            setError('Failed to delete message. Please try again.');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleAddMessage();
    };

    const handleNextStep = async () => {
        if (requestId && messages.length > 0) {
            setIsLoading(true);
            setError(null);
            try {
                await analyzeAndAddProducts(requestId);
                navigate('/request-control-list', { state: { requestId } });
            } catch (error) {
                setError('Failed to process request. Please try again.');
            } finally {
                setIsLoading(false);
            }
        } else if (messages.length === 0) {
            setError('Please add at least one message before proceeding.');
        }
    };

    return (
        <div className="dashboard">
            <Navbar isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar} />
            <main className="main-content">
                <div className="message-area" id="messageArea">
                    {messages.map((msg) => (
                        <div key={msg.id} className="message">
                            {editingMessageId === msg.id ? (
                                <input
                                    type="text"
                                    value={editedMessageContent}
                                    onChange={(e) => setEditedMessageContent(e.target.value)}
                                    onBlur={() => handleSaveEdit(msg.id)}
                                    className="edit-message-input"
                                />
                            ) : (
                                <div className="message-content">{msg.content}</div>
                            )}
                            <div className="message-actions">
                                {editingMessageId === msg.id ? (
                                    <>
                                        <FaSave onClick={() => handleSaveEdit(msg.id)} disabled={isLoading} />
                                        <FaTimes onClick={handleCancelEdit} disabled={isLoading} />
                                    </>
                                ) : (
                                    <>
                                        <FaEdit 
                                            onClick={() => handleEditMessage(msg.id, msg.content)} 
                                            disabled={isLoading}
                                            className="edit-icon"
                                        />
                                        <FaTrash 
                                            onClick={() => handleDeleteMessage(msg.id)} 
                                            disabled={isLoading}
                                            className="delete-icon"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                    <div ref={messageEndRef} />
                </div>
                <div className="input-area">
                    <Input 
                        type="text" 
                        className="message-input" 
                        placeholder="Type your message..." 
                        value={message}
                        onChange={handleMessageChange}
                        onKeyPress={handleKeyPress}
                        disabled={isLoading}
                    />
                    <FaPaperclip className="attach-icon" title="Attach File" />
                    <div className="action-buttons">
                        <Button onClick={handleAddMessage} disabled={isLoading || !message.trim()}>
                            Add
                        </Button>
                        <Button onClick={handleNextStep} disabled={isLoading || messages.length === 0}>
                            {isLoading ? 'Processing...' : 'Next Step'}
                        </Button>
                    </div>
                </div>
                {error && <div className="error-message">{error}</div>}
            </main>
        </div>
    );
};

export default CreateRequest;
