import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import CreateRequest from './pages/CreateRequest';
import RequestControlList from './pages/RequestControlList';
import OfferSummary from './pages/OfferSummary';
import Login from './pages/Login';
import MyProfile from './pages/MyProfile';
import Settings from './pages/Settings';
import Products from './pages/Products';
import MyCustomers from './pages/MyCustomers';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/create-request" element={<CreateRequest />} />
            <Route path="/request-control-list" element={<RequestControlList />} />
            <Route path="/offer-summary" element={<OfferSummary />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/products" element={<Products />} />
            <Route path="/my-customers" element={<MyCustomers />} />
            <Route path="/my-profile" element={<MyProfile />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;