import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/common/Button';
import Input from '../components/common/Input';

const MyProfile = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [profile, setProfile] = useState({
        name: 'John Doe',
        email: 'john.doe@example.com',
        position: 'Sales Manager',
        company: 'TechCorp Inc.'
    });
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!profile.name.trim()) newErrors.name = "Name is required";
        if (!profile.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(profile.email)) {
            newErrors.email = "Email is invalid";
        }
        if (!profile.position.trim()) newErrors.position = "Position is required";
        if (!profile.company.trim()) newErrors.company = "Company is required";
        return newErrors;
    };

    const handleSave = () => {
        const newErrors = validateForm();
        if (Object.keys(newErrors).length === 0) {
            // Here you would typically send the updated profile to your backend
            console.log('Saving profile:', profile);
            setNotification({ type: 'success', message: 'Profile updated successfully!' });
            setTimeout(() => setNotification(null), 3000);
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <Layout isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar}>
            <div className="my-profile">
                <h1>My Profile</h1>
                {notification && (
                    <div className={`notification ${notification.type}`}>
                        {notification.message}
                    </div>
                )}
                <div className="profile-form">
                    <div className="form-group">
                        <label>Name:</label>
                        <Input 
                            type="text" 
                            name="name" 
                            value={profile.name} 
                            onChange={handleInputChange} 
                            error={errors.name}
                        />
                        {errors.name && <span className="error-message">{errors.name}</span>}
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <Input 
                            type="email" 
                            name="email" 
                            value={profile.email} 
                            onChange={handleInputChange} 
                            error={errors.email}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label>Position:</label>
                        <Input 
                            type="text" 
                            name="position" 
                            value={profile.position} 
                            onChange={handleInputChange} 
                            error={errors.position}
                        />
                        {errors.position && <span className="error-message">{errors.position}</span>}
                    </div>
                    <div className="form-group">
                        <label>Company:</label>
                        <Input 
                            type="text" 
                            name="company" 
                            value={profile.company} 
                            onChange={handleInputChange} 
                            error={errors.company}
                        />
                        {errors.company && <span className="error-message">{errors.company}</span>}
                    </div>
                    <Button onClick={handleSave}>Save Changes</Button>
                </div>
            </div>
        </Layout>
    );
};

export default MyProfile;