import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/common/Button';


const ToggleSwitch = ({ isOn, onToggle }) => (
    <label className="switch">
        <input type="checkbox" checked={isOn} onChange={onToggle} />
        <span className="slider round"></span>
    </label>
);

const Settings = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [settings, setSettings] = useState({
        notifications: true,
        darkMode: false,
        language: 'English',
        emailFrequency: 'daily',
        fontSize: 'medium',
        autoSave: true,
        twoFactorAuth: false
    });
    const [notification, setNotification] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const handleToggle = (setting) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [setting]: !prevSettings[setting]
        }));
    };

    const handleChange = (setting, value) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [setting]: value
        }));
    };

    const handleSave = () => {
        console.log('Saving settings:', settings);
        // Here you would typically send the updated settings to your backend
        setNotification({ type: 'success', message: 'Settings saved successfully!' });
        setTimeout(() => setNotification(null), 3000);
    };

    return (
        <Layout isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar}>
            <div className="settings">
                <h1>Settings</h1>
                {notification && (
                    <div className={`notification ${notification.type}`}>
                        {notification.message}
                    </div>
                )}
                <div className="settings-container">
                    <div className="settings-section">
                        <h2>General</h2>
                        <div className="form-group">
                            <label>Language</label>
                            <select 
                                value={settings.language} 
                                onChange={(e) => handleChange('language', e.target.value)}
                                className="select-input"
                            >
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                                <option value="French">French</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Font Size</label>
                            <select 
                                value={settings.fontSize} 
                                onChange={(e) => handleChange('fontSize', e.target.value)}
                                className="select-input"
                            >
                                <option value="small">Small</option>
                                <option value="medium">Medium</option>
                                <option value="large">Large</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Dark Mode</label>
                            <ToggleSwitch 
                                isOn={settings.darkMode} 
                                onToggle={() => handleToggle('darkMode')} 
                            />
                        </div>
                    </div>
                    <div className="settings-section">
                        <h2>Notifications</h2>
                        <div className="form-group">
                            <label>Enable Notifications</label>
                            <ToggleSwitch 
                                isOn={settings.notifications} 
                                onToggle={() => handleToggle('notifications')} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Email Frequency</label>
                            <select 
                                value={settings.emailFrequency} 
                                onChange={(e) => handleChange('emailFrequency', e.target.value)}
                                className="select-input"
                            >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                    </div>
                    <div className="settings-section">
                        <h2>Security</h2>
                        <div className="form-group">
                            <label>Two-Factor Authentication</label>
                            <ToggleSwitch 
                                isOn={settings.twoFactorAuth} 
                                onToggle={() => handleToggle('twoFactorAuth')} 
                            />
                        </div>
                    </div>
                    <div className="settings-section">
                        <h2>Other</h2>
                        <div className="form-group">
                            <label>Auto-Save</label>
                            <ToggleSwitch 
                                isOn={settings.autoSave} 
                                onToggle={() => handleToggle('autoSave')} 
                            />
                        </div>
                    </div>
                </div>
                <Button onClick={handleSave} className="save-button">Save Settings</Button>
            </div>
        </Layout>
    );
};

export default Settings;