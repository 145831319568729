import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.thezyia.com/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('API Request:', config.method.toUpperCase(), config.url);
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log('API Response:', response.status, response.config.url);
    return response;
  },
  (error) => {
    console.error('API Response Error:', error.response?.status, error.response?.data, error.config.url);
    
    if (error.response && error.response.status === 401 || error.code === 'ERR_NETWORK') {
      clearLocalStorageAndRedirect();
    }

    return Promise.reject(error);
  }
);

const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);
  clearLocalStorageAndRedirect();
  throw error;
};

const clearLocalStorageAndRedirect = () => {
  localStorage.removeItem('currentRequestId');
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('offerId');
  
  window.location.href = '/login';
};

// Existing endpoints
export const generateOfferPDF = (offerId) => 
  api.get(`/offers/${offerId}/pdf`).catch(error => handleApiError(error, 'Error generating PDF:'));

export const generateOfferExcel = (offerId) => 
  api.get(`/offers/${offerId}/excel`).catch(error => handleApiError(error, 'Error generating Excel:'));

export const createOfferFromRequest = (requestId, customerId) => 
  api.get(`/offers/create/${requestId}/${customerId}`).catch(error => handleApiError(error, 'Error creating offer from request:'));

export const analyzeAndAddProducts = (requestId) => 
  api.get(`/requests/${requestId}/analyze-and-add-products`).catch(error => handleApiError(error, 'Error analyzing and adding products:'));

export const addMessage = (requestId, content) => 
  api.post(`/requests/${requestId}/messages`, { content }).catch(error => handleApiError(error, 'Error adding message:'));

export const getRequestMessages = (requestId) => 
  api.get(`/requests/${requestId}/messages`).catch(error => handleApiError(error, 'Error getting request messages:'));

export const register = (userData) => 
  api.post('/users/register', userData).catch(error => handleApiError(error, 'Error registering user:'));

export const login = (credentials) => 
  api.post('/users/login', credentials).catch(error => handleApiError(error, 'Error logging in:'));

export const getUserRequests = () => 
  api.get('/requests').catch(error => handleApiError(error, 'Error getting user requests:'));

export const createRequest = (requestData) => 
  api.post('/requests', requestData).catch(error => handleApiError(error, 'Error creating request:'));

export const getRequestProducts = (requestId) => 
  api.get(`/requests/${requestId}/products`).catch(error => handleApiError(error, 'Error getting request products:'));

export const updateRequestProduct = (requestId, productId, productData) => 
  api.put(`/requests/${requestId}/products/${productId}`, productData).catch(error => handleApiError(error, 'Error updating request product:'));

// Requeste ürün eklemek için yeni endpoint
export const addProductToRequest = (requestId, productData) => 
  api.post(`/requests/${requestId}/products`, productData).catch(error => handleApiError(error, 'Error adding product to request:'));

export const updateMessage = (requestId, messageId, content) => 
  api.put(`/requests/${requestId}/messages/${messageId}`, { content }).catch(error => handleApiError(error, 'Error updating message:'));

export const deleteMessage = (requestId, messageId) => 
  api.delete(`/requests/${requestId}/messages/${messageId}`).catch(error => handleApiError(error, 'Error deleting message:'));

export const deleteAllMessages = (requestId) => 
  api.delete(`/requests/${requestId}/messages`).catch(error => handleApiError(error, 'Error deleting all messages:'));

export const getCustomers = () => 
  api.get('/customers').catch(error => handleApiError(error, 'Error getting customers:'));

// Get product by requestId and product_code
export const getProductByCode = (requestId, productCode) => 
  api.get(`/products/${requestId}/${productCode}`).catch(error => handleApiError(error, 'Error getting product by code:'));

// Get stock products
export const getStockProducts = () => 
  api.get('/products/stock').catch(error => handleApiError(error, 'Error getting stock products:'));

export const deleteRequestProduct = (requestId, productId) => 
  api.delete(`/products/request/${requestId}/${productId}`).catch(error => handleApiError(error, 'Error deleting request product:'));

export const addRequestProduct = (requestId, productData) => 
  api.post(`/products/request/${requestId}`, productData).catch(error => handleApiError(error, 'Error adding request product:'));

// Offer product routes
export const updateOfferProduct = (offerId, productId, productData) => 
  api.put(`/products/offer/${offerId}/${productId}`, productData).catch(error => handleApiError(error, 'Error updating offer product:'));

export const deleteOfferProduct = (offerId, productId) => 
  api.delete(`/products/offer/${offerId}/${productId}`).catch(error => handleApiError(error, 'Error deleting offer product:'));

export const addOfferProduct = (offerId, productData) => 
  api.post(`/products/offer/${offerId}`, productData).catch(error => handleApiError(error, 'Error adding offer product:'));

export default api;
