import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Navbar = ({ isSidebarActive, toggleSidebar }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const menuItems = [
        { path: "/", icon: "fas fa-tachometer-alt", text: "Dashboard" },
        { path: "/my-profile", icon: "fas fa-user", text: "My Profile", disabled: true },
        { path: "/my-customers", icon: "fas fa-users", text: "My Customers", disabled: true },
        { path: "/settings", icon: "fas fa-cog", text: "Settings", disabled: true },
        { path: "/products", icon: "fas fa-box", text: "Products", disabled: true },
    ];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <>
            <div 
                className={`overlay ${isSidebarActive ? 'active' : ''}`} 
                onClick={toggleSidebar}
                role="button"
                aria-label="Close sidebar"
            ></div>
            <button 
                className="sidebar-toggle" 
                onClick={toggleSidebar}
                aria-label={isSidebarActive ? "Close sidebar" : "Open sidebar"}
            >
                <i className="fas fa-bars"></i>
            </button>
            <nav className={`sidebar ${isSidebarActive ? 'active' : ''}`}>
                <div className="sidebar-header">
                    <img src="https://via.placeholder.com/100" alt="User Avatar" />
                    <h3>{user?.first_name} {user?.last_name}</h3>
                    <p>{user?.role}</p>
                </div>
                <ul className="sidebar-menu">
                    {menuItems.map((item) => (
                        <li key={item.path}>
                            <Link 
                                to={item.disabled ? "#" : item.path} 
                                className={`${location.pathname === item.path ? "active" : ""} ${item.disabled ? "disabled" : ""}`}
                                onClick={item.disabled ? (e) => e.preventDefault() : undefined}
                            >
                                <i className={item.icon}></i> {item.text}
                            </Link>
                        </li>
                    ))}
                    <li>
                        <a href="#" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Navbar;
