import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';

const OfferList = ({ title, offers, formatDate }) => {

    const handleOpenFile = (requestNumber, fileType) => {
        const baseUrl = process.env.REACT_APP_BASE_URL || 'https://thezyia.com';
        const fileUrl = `${baseUrl}/${fileType === 'pdf' ? 'pdf' : 'excel'}/${requestNumber}.${fileType}`;

        window.open(fileUrl, '_blank');
    };

    return (
        <div className="offer-list">
            <h2>{title}</h2>
            {offers.length > 0 ? (
                <ul>
                    {offers.map(offer => (
                        <li 
                            key={offer.id} 
                            className="offer-item"
                            onClick={() => handleOpenFile(offer.request_number, offer.file_type)}
                        >
                            <div className="offer-info" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {/* Icon en başta ve title ile arada az boşluk */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {offer.file_type === 'pdf' && (
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" title="PDF File" style={{ marginRight: '8px' }} />
                                    )}
                                    {offer.file_type === 'excel' && (
                                        <FontAwesomeIcon icon={faFileExcel} size="lg" title="Excel File" style={{ marginRight: '8px' }} />
                                    )}
                                    {!offer.file_type && (
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" title="No File Available" style={{ marginRight: '8px' }} />
                                    )}
                                    {/* Title */}
                                    <span className="offer-number">{offer.request_number}</span>
                                </div>
                                
                                {/* Sağda tarih/saat */}
                                <span className="offer-date">{formatDate(offer.created_at)}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="empty-list">
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
                    <p>No offers available.</p>
                </div>
            )}
        </div>
    );
};

export default OfferList;
