import api from './api';

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
  }
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

export const validateUserInput = (userData) => {
  const errors = {};

  if (!userData.first_name) errors.first_name = 'First name is required';
  if (!userData.last_name) errors.last_name = 'Last name is required';
  
  if (!userData.email) {
    errors.email = 'Email is required';
  } else if (!validateEmail(userData.email)) {
    errors.email = 'Invalid email format';
  }

  if (!userData.password) {
    errors.password = 'Password is required';
  } else if (!validatePassword(userData.password)) {
    errors.password = 'Password must be at least 8 characters long, contain 1 uppercase, 1 lowercase and 1 number';
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0
  };
};