import React, { createContext, useState, useEffect, useCallback } from 'react';
import { login, register, getUserRequests } from '../services/api';
import { setAuthToken, validateUserInput } from '../services/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRequests, setUserRequests] = useState([]);

  const loadUser = useCallback(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (token && storedUser) {
      setAuthToken(token);
      setUser(JSON.parse(storedUser));
      fetchUserRequests();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const fetchUserRequests = async () => {
    try {
      const response = await getUserRequests();
      setUserRequests(response.data);
    } catch (error) {
      console.error('Error fetching user requests:', error);
      setError('Failed to fetch user requests');
    }
  };

  const loginUser = async (credentials) => {
    try {
      setError(null);
      const response = await login(credentials);
      const { user, token } = response.data;
      setUser(user);
      setAuthToken(token);
      localStorage.setItem('user', JSON.stringify(user));
      await fetchUserRequests();
      return user;
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during login');
      throw error;
    }
  };

  const registerUser = async (userData) => {
    const { errors, isValid } = validateUserInput(userData);
    if (!isValid) {
      setError(errors);
      throw errors;
    }

    try {
      setError(null);
      const response = await register(userData);
      const { user, token } = response.data;
      setUser(user);
      setAuthToken(token);
      localStorage.setItem('user', JSON.stringify(user));
      await fetchUserRequests();
      return user;
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during registration');
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setAuthToken(null);
    setUserRequests([]);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  const checkAuthStatus = () => {
    return !!user;
  };

  const value = {
    user,
    loading,
    error,
    userRequests,
    login: loginUser,
    register: registerUser,
    logout,
    updateUser,
    checkAuthStatus,
    setError,
    fetchUserRequests
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};