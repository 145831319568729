import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import CustomerListModal from '../components/customers/CustomerListModal';
import Button from '../components/common/Button';
import Edit from '../components/common/Edit';
import { getRequestProducts, updateRequestProduct, deleteRequestProduct, getCustomers, createOfferFromRequest, getStockProducts, addProductToRequest, getProductByCode } from '../services/api';
import searchProducts  from '../utils/improvedProductSearch';

const RequestControlList = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [stockProducts, setStockProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({
        name: '',
        quantity: 0,
        product_code: '',
        unit_price: 0,
        light_color: '',
        case_color: '',
        watt: '',
        dimension: '',
        brand: '',
        currency: 'USD',
        category: '',
    });
    const [editingAll, setEditingAll] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const requestId = location.state?.requestId;
    const [searchResults, setSearchResults] = useState([]);
    useEffect(() => {
        if (requestId) {
            fetchRequestProducts();
            fetchCustomers();
        } else {
            console.error('No requestId provided');
            navigate('/');
        }
    }, [requestId, navigate]);

    useEffect(() => {
        if (customers.length > 0) {
            setSelectedCustomer(customers[0]);  // İlk müşteriyi varsayılan olarak seç
        }
    }, [customers]);

    // Talep edilen ürünleri çek
    const fetchRequestProducts = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await getRequestProducts(requestId);
            if (response.data.products && response.data.products.length > 0) {
                setProducts(response.data.products.map(product => ({ ...product, isEditing: false })));
            } else {
                setError('No products found for this request.');
            }
        } catch (error) {
            setError('Error fetching request products.');
            console.error('Error fetching request products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Müşterileri çek
    const fetchCustomers = async () => {
        try {
            const response = await getCustomers();
            if (response.data.customers && response.data.customers.length > 0) {
                setCustomers(response.data.customers);
            } else {
                setError('No customers found.');
            }
        } catch (error) {
            setError('Error fetching customers.');
            console.error('Error fetching customers:', error);
        }
    };

    // Stok ürünlerini çek
    const fetchStockProducts = async () => {
        try {
            const response = await getStockProducts();
            if (response.data.products && response.data.products.length > 0) {
                setStockProducts(response.data.products);
            } else {
                setError('No stock products found.');
            }
        } catch (error) {
            setError('Error fetching stock products.');
            console.error('Error fetching stock products:', error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    const toggleEditForm = (productId) => {
        setProducts(products.map(product => 
            product.id === productId ? { ...product, isEditing: !product.isEditing } : product
        ));
    };

    const saveChanges = async (updatedProduct) => {
        try {
            const response = await updateRequestProduct(requestId, updatedProduct.id, updatedProduct);
            if (response.data && response.data.status) {
                setProducts(products.map(product =>
                    product.id === updatedProduct.id ? { ...updatedProduct, isEditing: false } : product
                ));
                console.log(`Changes saved for product ${updatedProduct.id}`);
            } else {
                throw new Error(response.data.message || 'Error updating product.');
            }
        } catch (error) {
            setError('Error updating product.');
            console.error('Error updating product:', error);
        }
    };

    const editAllProducts = () => {
        setEditingAll(!editingAll);
        setProducts(products.map(product => ({ ...product, isEditing: !editingAll })));
    };

    const saveAllChanges = async () => {
        try {
            await Promise.all(products.map(product => updateRequestProduct(requestId, product.id, product)));
            setEditingAll(false);
            setProducts(products.map(product => ({ ...product, isEditing: false })));
            console.log('All changes saved');
        } catch (error) {
            setError('Error saving all changes.');
            console.error('Error saving all changes:', error);
        }
    };

    const selectCustomer = (customer) => {
        setSelectedCustomer(customer);
        setIsCustomerModalOpen(false);
        console.log(`Selected customer: ${customer.first_name} ${customer.last_name} from ${customer.company_name || 'Unknown Company'}`);
    };

    const handleNextStep = async () => {
        if (!selectedCustomer) {
            setError('Please select a customer before proceeding.');
            return;
        }

        try {
            const response = await createOfferFromRequest(requestId, selectedCustomer.id);
            const offerId = response.data.offer.id;
            if (offerId) {
                localStorage.setItem('offerId', offerId);
                navigate('/offer-summary', { state: { offerId, customer: selectedCustomer } });
            } else {
                setError('Failed to create offer. Please try again.');
            }
        } catch (error) {
            console.error('Error creating offer:', error);
            setError('Failed to create offer. Please try again.');
        }
    };

    const confirmDelete = (productId) => {
        setDeleteConfirmation(productId);
    };

    const cancelDelete = () => {
        setDeleteConfirmation(null);
    };

    const handleDelete = async (productId) => {
        try {
            await deleteRequestProduct(requestId, productId);
            setProducts(products.filter(product => product.id !== productId));
            setDeleteConfirmation(null);
            console.log(`Product ${productId} deleted successfully`);
        } catch (error) {
            setError('Error deleting product.');
            console.error('Error deleting product:', error);
        }
    };

    const openAddProductModal = () => {
        setIsAddProductModalOpen(true);
        fetchStockProducts();
    };

    const closeAddProductModal = () => {
        setIsAddProductModalOpen(false);
        setSearchTerm('');
    };

    const openNewProductModal = () => {
        setIsNewProductModalOpen(true);
    };

    const closeNewProductModal = () => {
        setIsNewProductModalOpen(false);
        setNewProduct({
            name: '',
            quantity: 0,
            product_code: '',
            unit_price: 0,
            light_color: '',
            case_color: '',
            watt: '',
            dimension: '',
            brand: '',
            currency: 'USD',
            category: '',
        });
    };

    const handleNewProductChange = (e) => {
        setNewProduct({
            ...newProduct,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddNewProduct = async () => {
        const { name, quantity, unit_price, product_code } = newProduct;
        
        if (!name || !quantity || !unit_price || !product_code) {
            setError('Please fill in all required fields.');
            return;
        }

        try {
            await addProductToRequest(requestId, newProduct);
            setProducts([...products, newProduct]);
            closeNewProductModal();
        } catch (error) {
            setError('Error adding new product.');
            console.error('Error adding new product:', error);
        }
    };



    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm.length > 2) {
            const results = searchProducts(searchTerm, stockProducts);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    const filteredProducts = stockProducts.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="dashboard">
            <Navbar isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar} />
            <main className="main-content">
                {error && <div className="error-message">{error}</div>}
                <div className="action-buttons">
                    <Button onClick={() => setIsCustomerModalOpen(true)}>
                        {selectedCustomer ? `Customer: ${selectedCustomer.first_name} ${selectedCustomer.last_name}` : 'Select Customer'}
                    </Button>
                    <Button onClick={editingAll ? saveAllChanges : editAllProducts}>
                        {editingAll ? 'Save All Changes' : 'Edit All Products'}
                    </Button>
                    <Button onClick={openAddProductModal}>Add Product from Stock</Button>
                    <Button onClick={openNewProductModal}>Add New Product</Button>
                    <Button onClick={handleNextStep}>Next Step</Button>
                </div>
                {products.length > 0 ? (
                    <div className="product-list">
                        {products.map(product => (
                            <div key={product.id} className="product-item">
                                {(product.isEditing || editingAll) ? (
                                    <Edit 
                                        product={product} 
                                        onSave={saveChanges} 
                                        onCancel={() => toggleEditForm(product.id)}
                                    />
                                ) : (
                                    <>
                                        <div className="product-info">
                                            <i className="fas fa-box product-icon"></i>
                                            <div className="product-details">
                                                <div className="product-name">{product.name}</div>
                                                <div className="product-meta">
                                                    Brand: {product.brand} | Price: {product.unit_price} {product.currency} | 
                                                    Quantity: {product.quantity} | Code: {product.product_code} | 
                                                    Watt: {product.watt} | Category: {product.category || 'N/A'} | 
                                                    Light Color: {product.light_color} | Case Color: {product.case_color}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-actions">
                                            <Button className="edit-button" onClick={() => toggleEditForm(product.id)}>
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                            {deleteConfirmation === product.id ? (
                                                <div className="delete-confirmation">
                                                    <span>Are you sure?</span>
                                                    <Button onClick={() => handleDelete(product.id)}>Yes</Button>
                                                    <Button onClick={cancelDelete}>No</Button>
                                                </div>
                                            ) : (
                                                <Button className="delete-button" onClick={() => confirmDelete(product.id)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No products available for this request.</div>
                )}
            </main>

            <CustomerListModal 
                isOpen={isCustomerModalOpen}
                onClose={() => setIsCustomerModalOpen(false)}
                customers={customers}
                onSelectCustomer={selectCustomer}
                selectedCustomer={selectedCustomer}
            />

{isAddProductModalOpen && (
    <div className="unique-modal">
        <div className="unique-modal-content">
            <h2 className="unique-modal-title">Add Product from Stock</h2>
            <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={handleSearch}
                className="unique-search-input"
            />
            <div className="unique-product-list">
                {searchResults.map(product => (
                    <div key={product.id} className="unique-product-item">
                        <div className="unique-product-details">
                            <span>{product.product_code}</span>
                            <span>{product.watt}</span>
                            <span>{product.name}</span>
                            <span>{product.brand}</span>
                            <span>Score: {product.score.toFixed(2)}</span>
                        </div>
                        <button className="unique-add-button" onClick={() => addProductToRequest(requestId, product)}>Add</button>
                    </div>
                ))}
            </div>
            <button className="unique-close-button" onClick={closeAddProductModal}>Close</button>
        </div>
    </div>
)}

{/* Yeni Ürün Modal */}
{isNewProductModalOpen && (
    <div className="unique-modal">
        <div className="unique-modal-content">
            <h2 className="unique-modal-title">Add New Product</h2>
            <div className="unique-modal-body">
                {/* Ürün Adı */}
                <div className="form-group">
                    <label htmlFor="product_name">Product Name <span className="required-field">(required)</span></label>
                    <input
                        id="product_name"
                        type="text"
                        placeholder="Enter the product name"
                        name="name"
                        value={newProduct.name}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Miktar */}
                <div className="form-group">
                    <label htmlFor="quantity">Quantity <span className="required-field">(required)</span></label>
                    <input
                        id="quantity"
                        type="number"
                        placeholder="Enter quantity"
                        name="quantity"
                        value={newProduct.quantity}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Ürün Kodu */}
                <div className="form-group">
                    <label htmlFor="product_code">Product Code <span className="required-field">(required)</span></label>
                    <input
                        id="product_code"
                        type="text"
                        placeholder="Enter the product code"
                        name="product_code"
                        value={newProduct.product_code}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Birim Fiyatı */}
                <div className="form-group">
                    <label htmlFor="unit_price">Unit Price <span className="required-field">(required)</span></label>
                    <input
                        id="unit_price"
                        type="number"
                        placeholder="Enter unit price"
                        name="unit_price"
                        value={newProduct.unit_price}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Işık Rengi */}
                <div className="form-group">
                    <label htmlFor="light_color">Light Color</label>
                    <input
                        id="light_color"
                        type="text"
                        placeholder="Enter light color"
                        name="light_color"
                        value={newProduct.light_color}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Kasa Rengi */}
                <div className="form-group">
                    <label htmlFor="case_color">Case Color</label>
                    <input
                        id="case_color"
                        type="text"
                        placeholder="Enter case color"
                        name="case_color"
                        value={newProduct.case_color}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>

                {/* Marka */}
                <div className="form-group">
                    <label htmlFor="brand">Brand</label>
                    <input
                        id="brand"
                        type="text"
                        placeholder="Enter brand name"
                        name="brand"
                        value={newProduct.brand}
                        onChange={handleNewProductChange}
                        className="input-field"
                    />
                </div>
            </div>

            <div className="unique-modal-footer">
                <Button onClick={handleAddNewProduct}>Add Product</Button>
                <Button onClick={closeNewProductModal}>Cancel</Button>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default RequestControlList;
