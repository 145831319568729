import levenshtein from 'fast-levenshtein';

const normalizeText = (text) => {
    if (typeof text !== 'string') return '';
    return text
        .toLowerCase()
        .replace(/ç/g, 'c')
        .replace(/ğ/g, 'g')
        .replace(/ı/g, 'i')
        .replace(/ö/g, 'o')
        .replace(/ş/g, 's')
        .replace(/ü/g, 'u')
        .replace(/[^a-z0-9]/g, '');
};

const calculateSimilarityScore = (source, target) => {
    const normalizedSource = normalizeText(source);
    const normalizedTarget = normalizeText(target);
    const distance = levenshtein.get(normalizedSource, normalizedTarget);
    const maxLength = Math.max(normalizedSource.length, normalizedTarget.length);
    return (1 - distance / maxLength) * 100;
};

const calculateProductScore = (searchTerm, product) => {
    let score = 0;

    // Ürün adı benzerliği
    score += calculateSimilarityScore(searchTerm, product.name) * 0.4;

    // Ürün kodu benzerliği
    score += calculateSimilarityScore(searchTerm, product.product_code) * 0.3;

    // Marka benzerliği
    score += calculateSimilarityScore(searchTerm, product.brand) * 0.2;

    // Kategori benzerliği
    score += calculateSimilarityScore(searchTerm, product.category) * 0.1;

    // Watt değeri eşleşmesi
    const searchWatt = searchTerm.match(/\d+w/);
    if (searchWatt && product.watt === searchWatt[0]) {
        score += 10;
    }

    // Renk eşleşmesi
    const colors = ['beyaz', 'siyah', 'gri', 'mavi', 'kirmizi', 'sari', 'yesil'];
    colors.forEach(color => {
        if (normalizeText(searchTerm).includes(color) && normalizeText(product.case_color).includes(color)) {
            score += 5;
        }
    });

    return Math.min(score, 100); // Maksimum 100 puan
};

const searchProducts = (searchTerm, products) => {
    const scoredProducts = products.map(product => ({
        ...product,
        score: calculateProductScore(searchTerm, product)
    }));

    return scoredProducts
        .filter(product => product.score > 0)
        .sort((a, b) => b.score - a.score)
        .slice(0, 20);
};

export default searchProducts