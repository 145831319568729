import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import OfferList from '../components/offers/OfferList';
import useAuth from '../hooks/useAuth';
import { getUserRequests } from '../services/api';

const Dashboard = () => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [requests, setRequests] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth();

    const toggleSidebar = () => {
        setIsSidebarActive(!isSidebarActive);
    };

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await getUserRequests();
                console.log(response);
                setRequests(response.data);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        fetchRequests();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date().toDateString();
        const requestDate = date.toDateString();

        if (today === requestDate) {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return date.toLocaleDateString();
        }
    };

    // Tarihe göre sıralama fonksiyonu (yeniden eskiye doğru)
    const sortByDateDesc = (a, b) => new Date(b.created_at) - new Date(a.created_at);

    const todaysOffers = requests
        .filter(request => {
            const today = new Date().toDateString();
            const requestDate = new Date(request.created_at).toDateString();
            return today === requestDate;
        })
        .sort(sortByDateDesc);

    const olderOffers = requests
        .filter(request => {
            const today = new Date().toDateString();
            const requestDate = new Date(request.created_at).toDateString();
            return today !== requestDate;
        })
        .sort(sortByDateDesc);

    return (
        <Layout isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar}>
            <div className="header">             
                <button className="new-offer-btn" onClick={() => navigate('/create-request')}>Create New Offer</button>
            </div>
            <OfferList title="Today's Offers" offers={todaysOffers} formatDate={formatDate} />
            <OfferList title="Older Offers" offers={olderOffers} formatDate={formatDate} />
        </Layout>
    );
};

export default Dashboard;
