import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children, isSidebarActive, toggleSidebar }) => {
    return (
        <div className="dashboard">
            <Navbar isSidebarActive={isSidebarActive} toggleSidebar={toggleSidebar} />
            <main className={`main-content ${isSidebarActive ? 'sidebar-active' : ''}`}>
                {children}
            </main>
        </div>
    );
};

export default Layout;