import React from 'react';
import Modal from '../common/Modal';

const CustomerListModal = ({ isOpen, onClose, customers, onSelectCustomer }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Select a Customer">
            <div className="customer-list">
                {customers.length > 0 ? (
                    customers.map(customer => (
                        <div 
                            key={customer.id} 
                            className="customer-item" 
                            onClick={() => onSelectCustomer(customer)}
                        >
                            <div className="customer-name">{`${customer.first_name} ${customer.last_name}`}</div>
                            <div className="customer-company">{customer.company_name || 'Unknown Company'}</div>
                        </div>
                    ))
                ) : (
                    <div>No customers available.</div>
                )}
            </div>
        </Modal>
    );
};

export default CustomerListModal;
