import React, { useState } from 'react';
import Button from './Button';
import Input from './Input';

const Edit = ({ product, onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        name: product.name || '',
        brand: product.brand || '',
        unit_price: product.unit_price || '',
        quantity: product.quantity || '',
        product_code: product.product_code || '',
        watt: product.watt || '',
        category: product.category || '',
        light_color: product.light_color || '',
        currency: product.currency || '',
        case_color: product.case_color || '',
        dimension: product.dimension || '',
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSave = () => {
        onSave({ ...product, ...formData });
    };

    return (
        <div className="edit-inline">
            <div className="edit-row">
                <Input type="text" id="name" value={formData.name} onChange={handleInputChange} placeholder="Product Name" />
                <Input type="text" id="brand" value={formData.brand} onChange={handleInputChange} placeholder="Brand" />
                <Input type="number" id="unit_price" value={formData.unit_price} onChange={handleInputChange} step="0.01" placeholder="Unit Price" />
                <Input type="number" id="quantity" value={formData.quantity} onChange={handleInputChange} placeholder="Quantity" />
            </div>
            <div className="edit-row">
                <Input type="text" id="product_code" value={formData.product_code} onChange={handleInputChange} placeholder="Product Code" />
                <Input type="text" id="watt" value={formData.watt} onChange={handleInputChange} placeholder="Wattage" />
                <Input type="text" id="category" value={formData.category} onChange={handleInputChange} placeholder="Category" />
                <Input type="text" id="light_color" value={formData.light_color} onChange={handleInputChange} placeholder="Light Color" />
            </div>
            <div className="edit-row">
                <Input type="text" id="currency" value={formData.currency} onChange={handleInputChange} placeholder="Currency" />
                <Input type="text" id="case_color" value={formData.case_color} onChange={handleInputChange} placeholder="Case Color" />
                <Input type="text" id="dimension" value={formData.dimension} onChange={handleInputChange} placeholder="Dimension" />
            </div>
            <div className="edit-actions">
                <Button onClick={handleSave} className="save-btn">Save</Button>
                <Button onClick={onCancel} className="cancel-btn">Cancel</Button>
            </div>
        </div>
    );
};

export default Edit;
